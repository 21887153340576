<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo">
            <Logo />
          </span>
          <!-- <h2 class="brand-text mb-0">
            {{ companySettings.company_name }}
          </h2> -->
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import store from "@/store";
import { mapGetters } from "vuex";
import Logo from "@core/layouts/components/Logo.vue";
import { ref, computed } from "@vue/composition-api";
export default {
  components: {
    BLink,
    BImg,
    Logo,
  },
  // computed: {
  //   ...mapGetters({
  //     faviconURL: "settings/companyFaviconURL",
  //   }),
  // },
  // watch: {
  //   faviconURL(newFaviconURL) {
  //     document.getElementById("favicon").href = newFaviconURL;
  //   },
  // },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    const entity = localStorage.getItem("APP_IDENTITY");
    const fetchFavicon = async () => {
      await store.dispatch("settings/fetchCompanyFavicon");
    };
    fetchFavicon();
    document.getElementById("favicon").href =
      store.getters["settings/companyFaviconURL"];
    const companySettings = computed(() => {
      let settings = store.getters["settings/settingsByPath"](["company"])
        .settings;
      return settings;
    });
    return {
      appName,
      appLogoImage,
      companySettings,
      entity,
    };
  },
};
</script>

<style></style>
