<template>
  <div class="navbar-container d-flex content align-items-center">
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      style="padding-left: 130px"
    >
      <!-- <bookmarks /> -->
      <div class="d-flex">
        <div class="my-auto px-3">
          {{ user.full_name }}:
          <b-dropdown :text="ib_id ? `IB ${ib_id}` : ''" variant="flat-dark">
            <b-dropdown-item
              v-for="(ib, index) in ibs"
              :key="index"
              @click="setIb(ib)"
            >
              IB {{ ib.id }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="my-auto">
          Last update: {{ moment().format("DD/MM/YYYY HH:mm:ss") }}
        </div>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <locale />
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import DarkToggler from "./components/DarkToggler.vue";
import SearchBar from "./components/SearchBar.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import axios from "@axios";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/me",
      ibs: "partner/ibs",
      ib_id: "partner/currentIbId",
    }),
  },  

  methods: {
    moment,
    setIb(ib) {
      console.log("setIb", ib);
      this.$store.commit("app/SET_IB_ID", ib.id);
    },
  },
  mounted() {
  },

  watch: {

  },
};
</script>
<style>
.navbar-container .btn {
  font-weight: normal;
  padding: 5px 50px 5px 5px;
}
</style>
