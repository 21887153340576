import store from "@/store"

const defaultNavBar = [
  {
    icon: 'home-heart',
    title: 'Dashboard',
    route: 'dashboard'
  },
  {
    icon: 'users',
    title: 'Clients',
    route: 'clients',
  },
  {
    icon: 'user-tag',
    title: 'Leads',
    route: 'leads',
  },
  {
    icon: 'handshake',
    title: 'Partners',
    route: 'partners',
  },
  
  {
    icon: 'file-chart-line',
    title: 'Reports',
    route: 'reports',
  },
  
  {
    icon: 'cog',
    title: 'Settings',
    route: 'settings',
  }
]

const filteredNavMenuItems = (store) => {
  let result = defaultNavBar
  console.log(store.getters['partner/partners'])
  if (store.getters['partner/partners'].length == 0) {
    result = result.filter((item) => item.route != 'partners')
  }
  return result
}

export default filteredNavMenuItems